<template>
  <div
    class="box"
    tabindex="0"
    @click="emitBoxClick"
  >
    <div class="box__top">
      <h5 class="box__title">
        {{ title }}
      </h5>
      <slot name="top" />
    </div>
    <div class="box__bottom">
      <slot name="bottom" />
      <div class="box__link">
        {{ linkText }}
        <SvgIcon
          name="caret"
          width="2rem"
          height="2rem"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    linkText: {
      type: String,
      default: 'See more'
    },
    link: {
      type: String,
      default: null
    }
  },
  methods: {
    emitBoxClick() {
      this.$emit('box-click')
    }
  }
}
</script>

<style lang="scss" scoped>
  .box {
    width: 100%;
    height: 15rem;
    padding: 1rem;
    border: 2px solid $axon;
    background-color: $dendrite;
    transition: all .2s ease-in-out;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    &:hover,
    &:focus {
      color: $white-matter;
      background-color: $dopamine;
      border-color: $dopamine;
      box-shadow: 5px 5px 5px rgba($black, .15);

      svg {
        fill: $white-matter;
      }
    }

    &:focus {
      border: 2px solid $dopamine;
      outline: none;
    }

    &__title {
      margin: 0;
      @include text-style('title', 'extra-large', 'regular');
    }

    &__top {
      p {
        @include text-style('paragraph', 'medium', 'regular');
      }

      span {
        display: block;
      }
    }

    &__bottom {
      width: 100%;
    }

    &__label,
    &__link {
      display: block;
    }

    &__link {
      @include text-style('title', 'medium', 'bold');
    }

    &__link {
      margin-top: -.25rem;
      text-decoration: none;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg {
        transform: rotate(-90deg);
      }
    }

    @media screen and (min-width: $breakpoint-mobile) {
      width: calc(50% - 1rem);
    }

    @media screen and (min-width: 1025px) {
      width: calc(25% - 1.5rem); // 1.5 = 6rem margins divided by 4 elements
      height: 18rem;
    }
  }
</style>
