<!-- eslint-disable vue/this-in-template -->
<template>
  <BoxLink
    :title="study.name"
    link-text="See more details"
    @box-click="handleBoxClick"
  >
    <template slot="top">
      <p>
        <ApolloQuery
          tag="span"
          :query="require('@/graphql/cohorts/GetStudyCohortsQuery.graphql')"
          :variables="{ studyId: study.id, withExcludedCohorts: false }"
          :update="data => data.getStudyCohorts.reduce((total, cohort) => total + cohort.participantCount, 0)"
          @error="error => logGQLError(error, 'StudyBox.vue get study cohorts query')"
        >
          <template v-slot="{ result: { data: totalParticipantCount }, isLoading }">
            <SkeletonBox v-if="isLoading" />
            <template v-else>
              Total Participants: {{ totalParticipantCount }}
            </template>
          </template>
        </ApolloQuery>
        <ApolloQuery
          tag="span"
          :query="require('@/graphql/cohorts/GetStudyCohortsQuery.graphql')"
          :variables="{ studyId: study.id, withExcludedCohorts: false }"
          :update="data => this.getTotalEnrolledParticipants(data.getStudyCohorts)"
          @error="error => logGQLError(error, 'StudyBox.vue get study cohorts query')"
        >
          <template v-slot="{ result: { data: enrolledParticipantsCount }, isLoading }">
            <SkeletonBox v-if="isLoading" />
            <template v-else>
              Enrolled Participants: {{ enrolledParticipantsCount }}
            </template>
          </template>
        </ApolloQuery>
        <ApolloQuery
          tag="span"
          :query="require('@/graphql/sites/GetSitesQuery.graphql')"
          :variables="{ studyId: study.id }"
          :update="data => data.getSites"
          @error="error => logGQLError(error, 'StudyBox.vue get sites query')"
        >
          <template v-slot="{ result: { data: sites }, isLoading }">
            <SkeletonBox v-if="isLoading" />
            <template v-else>
              Sites: {{ sites ? sites.length : 0 }}
            </template>
          </template>
        </ApolloQuery>
      </p>
    </template>
  </BoxLink>
</template>

<script>
import SkeletonBox from '@/components/SkeletonBox/SkeletonBox'
import BoxLink from '@/components/BoxLink/BoxLink'
import GET_SITES_QUERY from '@/graphql/sites/GetSitesQuery.graphql'
import { logError } from '@/utils/logging'
import { ParticipantProtocolStatus } from '@/utils/constants'
import { propOr } from 'ramda'

export default {
  components: {
    BoxLink,
    SkeletonBox
  },
  props: {
    study: {
      type: Object,
      required: true
    }
  },
  apollo: {
    sites() {
      return {
        query: GET_SITES_QUERY,
        update: data => data.getSites,
        variables: {
          studyId: this.study.id
        },
        error (error) {
          this.logGQLError(error, 'StudyBox.vue get sites query')
        }
      }
    }
  },
  data() {
    return {
      sites: [],
      protocolStatusEnrolled: ParticipantProtocolStatus.ENROLLED
    }
  },
  methods: {
    /*
     * On box click, Navigate to Study Overview page
     */
    handleBoxClick() {
      this.$router.push({
        name: 'studyOverview',
        params: { studyId: this.study.id },
        query: { pagetitle: this.study.name }
      })
    },

    getTotalEnrolledParticipants(cohort) {
      return cohort
        .reduce((total, cohort) =>
          total + propOr(0, 'count',
            cohort.participantCountByStatus.find(count => count.status === ParticipantProtocolStatus.ENROLLED))
        , 0)
    },

    logGQLError(error, message) {
      logError(error, message)
    }
  }
}
</script>
