<template>
  <div class="studies-list">
    <PageHeader title="Studies" />
    <ContentCard>
      <div class="study-boxes">
        <div
          v-loading="$apollo.queries.studies.loading"
          class="study-boxes__container"
        >
          <StudyBox
            v-for="study in studies"
            :key="study.id"
            :study="study"
            tabindex="0"
          />
        </div>
      </div>
    </ContentCard>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader/PageHeader'
import ContentCard from '@/components/ContentCard/ContentCard'
import StudyBox from '@/components/StudyBox/StudyBox'
import studies from '@/mixins/queries/studies'

export default {
  components: {
    PageHeader,
    ContentCard,
    StudyBox
  },
  mixins: [ studies ]
}
</script>

<style lang="scss">
  .studies-list {
    height: 100%;

    .content-card {
      margin: 0;
    }
  }

  .study-boxes {
    &__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding: 1rem 0;
      gap: 2rem;

      .el-loading-spinner {
        top: 100px;
      }

      @media screen and (min-width: 1025px) {
        justify-content: flex-start;
      }
    }
  }
</style>
