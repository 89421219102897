var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BoxLink',{attrs:{"title":_vm.study.name,"link-text":"See more details"},on:{"box-click":_vm.handleBoxClick}},[_c('template',{slot:"top"},[_c('p',[_c('ApolloQuery',{attrs:{"tag":"span","query":require('@/graphql/cohorts/GetStudyCohortsQuery.graphql'),"variables":{ studyId: _vm.study.id, withExcludedCohorts: false },"update":function (data) { return data.getStudyCohorts.reduce(function (total, cohort) { return total + cohort.participantCount; }, 0); }},on:{"error":function (error) { return _vm.logGQLError(error, 'StudyBox.vue get study cohorts query'); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var totalParticipantCount = ref.result.data;
var isLoading = ref.isLoading;
return [(isLoading)?_c('SkeletonBox'):[_vm._v(" Total Participants: "+_vm._s(totalParticipantCount)+" ")]]}}])}),_c('ApolloQuery',{attrs:{"tag":"span","query":require('@/graphql/cohorts/GetStudyCohortsQuery.graphql'),"variables":{ studyId: _vm.study.id, withExcludedCohorts: false },"update":function (data) { return this$1.getTotalEnrolledParticipants(data.getStudyCohorts); }},on:{"error":function (error) { return _vm.logGQLError(error, 'StudyBox.vue get study cohorts query'); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var enrolledParticipantsCount = ref.result.data;
var isLoading = ref.isLoading;
return [(isLoading)?_c('SkeletonBox'):[_vm._v(" Enrolled Participants: "+_vm._s(enrolledParticipantsCount)+" ")]]}}])}),_c('ApolloQuery',{attrs:{"tag":"span","query":require('@/graphql/sites/GetSitesQuery.graphql'),"variables":{ studyId: _vm.study.id },"update":function (data) { return data.getSites; }},on:{"error":function (error) { return _vm.logGQLError(error, 'StudyBox.vue get sites query'); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var sites = ref.result.data;
var isLoading = ref.isLoading;
return [(isLoading)?_c('SkeletonBox'):[_vm._v(" Sites: "+_vm._s(sites ? sites.length : 0)+" ")]]}}])})],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }